<section class="py-5 w-100" style="position: fixed; top: 0; right: 0; left: 0; bottom: 0; background-color: rgba(4,4,4,.9);  z-index:234567">
    <div class="position-relative">
        <div class="btn-fechar" (click)="fecharModal()">
            <span><i class="bi-x-circle text-white"></i></span>
        </div>
    </div>
    <div class="container modal-wrapper ">

        <div class="row">
            <h4 class="text-white fw-bold text-uppercase titulo">Formulário de inscrição</h4>
        </div>
        <div class="row">
            <div class="col-xxl-6 col-lg-7 col-md-7 col-sm-9 bg-white col-11 mx-auto px-lg-5  py-5">

                <form [formGroup]="formInscricao" (ngSubmit)="fazerInscricao()" id="form-inscricao">
                    <div class="row">
                        <div class="col-md-12 my-lg-3 my-3">
                            <label for="nome" class="text-primary fw-normal">Nome completo*</label>
                            <input type="text" id="nome" class="form-control" formControlName="nome" placeholder="Escrever nome completo">
                            <!-- Validacoes -->
                            <div class="my-0 d-flex align-items-center" *ngIf="formInscricao.get('nome').invalid && formInscricao.get('nome').touched">
                                <small class="text-danger px-2" *ngIf="formInscricao.get('nome').errors.required">Escreva o nome</small>
                            </div>
                            <!-- / Validacoes -->
                        </div>
                        <div class="col-md-12 my-lg-3 my-3">
                            <label for="nome" class="text-primary fw-normal">E-mail*</label>
                            <input type="text" class="form-control" formControlName="email" placeholder="Escrever e-mail">
                            <!-- Validacoes -->
                            <div class="my-0 d-flex align-items-center" *ngIf="formInscricao.get('email').invalid && formInscricao.get('email').touched">
                                <small class="text-danger px-2" *ngIf="formInscricao.get('email').errors.required">Escreva um e-mail</small>
                                <small class="text-danger px-2" *ngIf="formInscricao.get('email').errors.email">Escreva um e-mail valido</small>
                            </div>
                            <!-- / Validacoes -->
                        </div>
                        <div class="col-md-6 my-lg-3 my-3">
                            <label for="nome" class="text-primary fw-normal">Telefone</label>
                            <input type="text" class="form-control" maxlength="20" formControlName="telefone" placeholder="Escrever telefone">
                            <!-- Validacoes -->
                            <div class="my-0 d-flex align-items-center" *ngIf="formInscricao.get('telefone').invalid && formInscricao.get('telefone').touched">
                                <small class="text-danger px-2" *ngIf="formInscricao.get('telefone').errors.required">Escreva um número de telefone</small>
                            </div>
                            <!-- / Validacoes -->
                        </div>
                        <div class="col-md-6 my-lg-3 my-3">
                            <label for="nome" class="text-primary fw-normal ">Tipo de inscri&ccedil;&atilde;o*</label>
                             <select type="text" class="form-control" formControlName="tipoCliente">
                                <option selected >Seleccionar </option>
                                <option *ngFor="let socio of candidaturas" value="{{socio.nome}}">{{socio.nome}}</option>
                              </select>
                            <!-- Validacoes -->
                            <div class="my-0 d-flex align-items-center" *ngIf="formInscricao.get('tipoCliente').invalid && formInscricao.get('tipoCliente').touched">
                                <small class="text-danger px-2" *ngIf="formInscricao.get('tipoCliente').errors.required">Seleccione uma opção</small>
                            </div>
                            <!-- / Validacoes -->
                        </div>
                        <div class="col-md-12 my-lg-3 my-3 pt-3">
                            <button class="btn bg-secondary rounded-0 text-white fw-light fs-4 px-5 w-100">
                                       <span *ngIf="estaProcessar" class="d-flex align-items-center">
                                          <div class="spinner-border spinner-border-sm text-light me-2" role="status">
                                             <span class="visually-hidden">Loading...</span>
                                          </div>
                                          <small>A processar...</small>
                                        </span>
                              <small class="px-1" *ngIf="!estaProcessar" [innerHTML]="btnTexto"></small>
                            </button>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    </div>
</section>
