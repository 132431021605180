import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordenar'
})
export class OrdenarPipe implements PipeTransform {
constructor() {
}
 public transform(items: any[]): any {
  let itemsOrdenados: any[] = [];
  if (items.length > 0) {
    itemsOrdenados = items.sort((a, b) => a.ordem - b.ordem);
    }
  return itemsOrdenados;
  }

}
