import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'fan',
    loadChildren: () => import('./views/fan/fan.module').then(m => m.FanModule)
  },
  {
    path: 'disciplinas',
    loadChildren: () => import('./views/natacao/natacao.module').then(m => m.NatacaoModule)
  },
  { path: 'informacao', loadChildren: () => import('./views/comunicacao/comunicacao.module').then(m => m.ComunicacaoModule)},
  { path: 'contactos', loadChildren: () => import('./views/contactos/contactos.module').then(m => m.ContactosModule) },
  { path: 'eventos',    loadChildren: () => import('./views/eventos/eventos.module').then(m => m.EventosModule)  },
  { path: 'galeria',  loadChildren: () => import('./views/galeria/galeria.module').then(m => m.GaleriaModule)  },
  { path: 'parceiros', loadChildren: () => import('./views/parceiros/parceiros.module').then(m => m.ParceirosModule) },
  { path: 'clubes', loadChildren: () => import('./views/clubes/clubes.module').then(m => m.ClubesModule) }

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: false, anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
