
<section class="bg-azul-marinho pt-2" id="sec-rodape">
  <div class="container">
    <div class="row">
      <div class="col-12  d-flex justify-content-center py-4">
        <div class="rodape-logo">
          <img  src="../../../../assets/images/logo/logo_federacao_angolana_de_natacao.png" style="width: 95px" alt="">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 px-0">
        <hr class="bg-white">
      </div>
    </div>
    <div class="row">
        <div class="col-md-3 col-sm-6 col-6 ">
          <h4>FAN</h4>
          <ul class="menu-lista">
            <li class="hvr-underline"><a routerLink="/">Home</a></li>
            <li class="hvr-underline"><a routerLink="/fan/quem-somos">A FAN</a></li>
            <li class="hvr-underline"><a routerLink="/disciplinas">Disciplinas</a></li>
            <li class="hvr-underline"><a routerLink="/informacao">Informação</a></li>
            <li class="hvr-underline"><a routerLink="/eventos">Eventos</a></li>
            <li class="hvr-underline"><a routerLink="/galeria">Galeria</a></li>
            <li class="hvr-underline"><a routerLink="/contactos">Contactos</a></li>
          </ul>
        </div>


      <div class="col-md-3 col-sm-6 col-6">
        <h4>Links &Uacute;teis </h4>
        <ul class="menu-lista">
          <li class="hvr-underline" *ngFor="let link of linksUteis"><a [href]="link?.url" target="_blank">{{link?.nome}}</a></li>
        </ul>
      </div>

      <div class="col-md-3 col-sm-6">
        <h4>Disciplinas</h4>
        <ul class="menu-lista">
          <li class="hvr-underline" *ngFor="let disciplina of disciplinas"><a [routerLink]="['/disciplinas', disciplina?.idRegisto, disciplina?.nome ]">{{disciplina?.nome}}</a></li>
        </ul>

      </div>


      <div class="col-md-3 col-sm-6" *ngIf="contactos">
        <h4>Contactos</h4>
        <ul class="menu-lista">
          <li class="hvr-underline" *ngIf="listaContactos?.emails?.length > 0">
          <span class="fw-bold text-white"></span>
            <ng-container *ngFor="let email of listaContactos?.emails; last as ultimo">
              <a class="small" href="mailto:{{email?.contacto">  {{email?.contacto}}  <span *ngIf="!ultimo">/</span> </a>
            </ng-container>
          </li>

          <li class="hvr-underline" *ngIf="listaContactos?.telefones?.length > 0">
            <ng-container *ngFor="let tel of listaContactos?.telefones; last as ultimo">
              <a class="small" href="tel:{{tel?.contacto">  {{tel?.contacto}}  <span *ngIf="!ultimo">/</span> </a>
            </ng-container>
          </li>

          <li class="hvr-underline" *ngIf="listaContactos?.telemoveis?.length > 0">
            <ng-container *ngFor="let tel of listaContactos?.telemoveis; last as ultimo">
              <a class="small" href="tel:{{tel?.contacto">  {{tel?.contacto}}  <span *ngIf="!ultimo">/</span> </a>
            </ng-container>
          </li>
        </ul>


      </div>
    </div>
    <div class="row">
      <div class="col-12 px-0">
        <hr class="bg-white">
      </div>
    </div>

    <div class="row">
      <div class="col-12 mb-4">
        <h4 class="text-uppercase text-center ">Filiados</h4>
      </div>
      <div class="row  pb-3">
        <owl-carousel-o [options]="opcoes">
          <ng-container *ngFor="let club of clubes; let i = index">
            <ng-template carouselSlide>
              <div class=" cliente-logo-wrapper">
                <div class="p-2 logo-img logo-clube-home cursor-pointer">
                  <img *ngIf="club.fotografia" [src]="club.fotografia" [alt]="club.nome">
                  <h6 *ngIf="!club.fotografia" ><small [innerText]="club.nome"></small></h6>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>

    </div>
    <div class="row">
      <div class="col-12 px-0">
        <hr class="bg-white">
      </div>
    </div>
    <div class="row pb-4 social-media" >
      <h4 *ngIf="redes?.length > 0" class="text-center text-uppercase">Siga-nos</h4>
      <div *ngIf="redes?.length > 0" class=" col-12 ps-0 text-white d-flex align-items-center justify-content-center mb-0">
        <ul>
          <ng-container *ngFor="let rede of redes">
            <li *ngIf="rede?.activo && rede?.codRedeSocial ==='FB'">
              <a target="_blank" [href]="rede?.url"><span><i class="bi-facebook"></i></span></a>
            </li>

            <li *ngIf="rede?.activo && rede?.codRedeSocial ==='TW'">
              <a target="_blank" [href]="rede?.url"><span><i class="bi-twitter"></i></span></a>
            </li>

            <li *ngIf="rede?.activo && rede?.codRedeSocial ==='LI'">
              <a target="_blank" [href]="rede?.url"><span><i class="bi-linkedin"></i></span></a>
            </li>

            <li *ngIf="rede?.activo && rede?.codRedeSocial ==='IN'">
              <a target="_blank" [href]="rede?.url"><span><i class="bi-instagram"></i></span></a>
            </li>

            <li *ngIf="rede?.activo && rede?.codRedeSocial ==='YT'">
              <a target="_blank" [href]="rede?.url"><span><i class="bi-youtube"></i></span></a>
            </li>

          </ng-container>

        </ul>

      </div>
    </div>
  </div>

  <div class="copy-right bg-copyright">
   <div class="container py-4 ps-0">
      <div class="d-flex justify-content-between text-white">
        <p class="mb-0 small fw-light">&copy; {{thisYear}}. Federa&ccedil;&atilde;o Angolana de Nata&ccedil;&atilde;o</p>
        <p class="mb-0 small fw-light">Desenvolvido por <span>
          <a href="http://www.kiamisoft.ao" class="text-white decor-none" target="_blank"><span style="color:#85a7f3">Kiami</span><span style="color:#428bca">Soft</span></a>
        </span>
        </p>
      </div>
   </div>
  </div>
</section>
