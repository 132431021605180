import {Component, OnDestroy, OnInit} from '@angular/core';
import {ViewportScroller} from '@angular/common';
import {Router, Scroll} from '@angular/router';
import {filter} from 'rxjs/operators';
import {SuporteService} from './core/servicos/suporte.service';
import {AppConfiguracao} from "./core/entidades/app-configuracao";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-root',
  template: `
    <header>
      <app-navbar [appConfiguracao]="appConfiguracao"></app-navbar>
    </header>
    <main class="min-vh-60">
      <router-outlet></router-outlet>
    </main>
    <footer>
      <app-rodape></app-rodape>
    </footer>
  `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'fan';
 appConfiguracao!: AppConfiguracao;
subs: Subscription;
  constructor( router: Router, viewportScroller: ViewportScroller, private suporteService: SuporteService) {
    router.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition([0, 0]);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        }, 100);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }

  ngOnInit(): void {
    this.getConfig();
  }

  getConfig(): void {
    this.subs = this.suporteService.getConfiguracoes().subscribe({
      next: (res: any) => {
        this.appConfiguracao = res.objectivo;
        const link = document.getElementById("favicon");
        console.log('Favicon: ', link);
        console.log('App Configuracao: ', this.appConfiguracao);
        if (link){
          //  link['href'] = this.appConfiguracao;
        }
      },
      error: (erro: any) => {}
    });
  }

  ngOnDestroy(): void {
    if(this.subs){
      this.subs.unsubscribe();
    }
  }
}
