import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from "rxjs";
import {Cliente} from "../entidades/cliente";


@Injectable({providedIn: 'root'})
export class ClienteService {
  api?: string;
  apiClientes?: string;
  parceiros$ = new BehaviorSubject<any>(null);
  afiliados$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    this.api = environment.api_fan_cms;
    this.apiClientes = environment.api_clientes;
  }

  getClientes(idEntidade: number, codigoFiltro: number): any {
  //  return this.http.post(this.apiClientes + 'clientes/listagem', { IdEntidade: idEntidade,  CodigoFiltro: 1, });
    return this.http.get(this.apiClientes + 'clientes/listagem/' + idEntidade);
  }

  setParceiros(parceiros: any): void {
    this.parceiros$.next(parceiros);
  }

  setAfiliados(afiliados: any): void {
    this.afiliados$.next(afiliados);
  }

  getParceiros(): any {
    return this.parceiros$.asObservable();
  }

  getAfiliados(): any {
    return this.afiliados$.asObservable();
  }
}
