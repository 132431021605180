import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Cliente} from '../../entidades/cliente';

@Injectable({
  providedIn: 'root'
})
export class ParceirosStoreService {
private parceiros = new BehaviorSubject<any>(null);

  constructor() { }

  setParceirosData(parceiros: Cliente[]): void{
    this.parceiros.next(parceiros);
  }

  getParceirosData(): Observable<Cliente[]>{
    return this.parceiros.asObservable();
  }

}
