// @ts-ignore

import {Pipe, PipeTransform} from '@angular/core';
import {Evento} from "../entidades/evento";

@Pipe({
  name: 'ordernarEventosPorData',
  pure: true
})
export class OrdenarEventosDataPipe implements PipeTransform {
  constructor() {}

  public transform(eventos: Evento[], ...args): any {
    let eventosOrdenados: any[] = [];
    if (eventos && eventos.length > 0) {
      eventosOrdenados = eventos.sort((a, b) => {
        const dtA = new Date(a.dataInicio).getTime();
        const dtB = new Date(b.dataInicio).getTime();
        return dtB > dtA ? 1 : -1;
      //  return dtA - dtB;
      });
    }
    return eventosOrdenados;
  }


}

