import { Pipe, PipeTransform } from '@angular/core';
import {Cliente} from "../../shared/entidades/cliente";

@Pipe({
  name: 'clienteDestaque',
  pure: true
})
export class FiltrarClientesDestaquePipe implements PipeTransform {
constructor() {
}
 public transform(clientes: Cliente[], destaque?: boolean): any {
  if (clientes && clientes.length > 0) {
      if (destaque){
        let clientesAtivos: Cliente[] = clientes.filter((x) => x.idTipo && x.idTipo === 3);
        return clientesAtivos.reverse();
      } else {
        return clientes.filter((x) => x.idTipo !== 3);
      }
    }
  }

}
