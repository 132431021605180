import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SecHeadComponent} from './sec-head/sec-head.component';
import {
  FormularioInscricaoComponent,
  SecAddComponent
} from '../../componentes/sec-add/sec-add.component';
import {PaginaIntroComponent} from './pagina-intro/pagina-intro.component';

import {RouterModule} from '@angular/router';
import {PartialParceirosComponent} from '../../componentes/partial-parceiros/partial-parceiros.component';
import {AppDownloadComponent} from '../../componentes/app-download/app-download.component';
import {LoaderComponent} from '../../componentes/loader/loader.component';
import {ReactiveFormsModule} from '@angular/forms';
import {FormInscricaoComponent} from '../../componentes/form-inscricao/form-inscricao.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import {SliderParceirosComponent} from "./slider-parceiros/slider-parceiros.component";
import {PipeModule} from "../../../core/pipes/pipe.module";


const componentes = [
  SecHeadComponent,
  SecAddComponent,
  FormInscricaoComponent,
  FormularioInscricaoComponent,
  AppDownloadComponent,
  PaginaIntroComponent,
  PartialParceirosComponent,
  LoaderComponent,
  SliderParceirosComponent
];

@NgModule({
  declarations: componentes,
  exports: componentes,
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        CarouselModule,
        PipeModule
    ]
})
export class PartialModule { }
