import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {RedeSocial} from '../modelos/rede-social';

@Injectable({
  providedIn: 'root'
})
export class ComunicacaoService {
  api: string;
  redesStore = new BehaviorSubject<RedeSocial[]>(null);

  constructor(private http: HttpClient) {
  //  this.api = environment.api_link;
  //  this.api = environment.api_kiami;
    this.api = environment.api_fan_cms;
  }

  getRedesSociais(): any{
    return  this.http.get(this.api + 'redesSocias');
  }
  setRedesStore(redesSociais: RedeSocial[]): void {
    this.redesStore.next(redesSociais);
  }
  getRedesStore(): any {
   return this.redesStore.asObservable();
  }

  getLocal(idLocal: number, codIdioma: string): any {
      return this.http.get(this.api + 'locais/local/' + idLocal + '/' + codIdioma);
  }
  getLocais(): any {
    return this.http.get(this.api + 'locais');
  }
  subscreverNewsletter(modelo: any): any{
    return this.http.post(this.api + 'newsletters', modelo);
  }

  gravarMsgContacto(modelo: any): any {
    return this.http.post(this.api + 'formulariosRegistos', modelo);
  }

}
