import {Component, OnDestroy, OnInit} from '@angular/core';
import {LinksUteis} from '../../modelos/links-uteis/links-uteis';
import {Subscription} from 'rxjs';
import {ContactoService} from '../../services/contacto/contacto.service';
import {DataStoreService} from '../../services/data-store/data-store.service';
import {RedesSociais} from '../../modelos/redes-sociais/redes-sociais';
import {Cliente} from '../../entidades/cliente';
import {owlFooterOpcoes} from '../../config/app.config';
import {ComunicacaoService} from '../../core/servicos/comunicacao-service';
import {AplicacaoService} from '../../services/aplicacao/aplicacao.service';
import {ClienteService} from '../../servicos/cliente.service';
import {RegistoService} from '../../../core/servicos/registo.service';
import {Registo} from '../../../core/entidades/registo';
import {ListaContacto} from '../../modelos/contacto/lista-contacto';

@Component({
  selector: 'app-rodape',
  templateUrl: './rodape.component.html',
  styleUrls: ['./rodape.component.scss']
})
export class RodapeComponent implements OnInit, OnDestroy {
contactos: any = {};
  listaContactos = new ListaContacto([], [], [], []);
  sede: any;
codIdioma = 'pt';
links: LinksUteis[] = [];
linksUteis: Registo[] = [];
idGrupo = 1;
idGrupoDisciplina = 3;
subscricoes: Subscription[] = [];
redes: RedesSociais[] = [];
clubes: Cliente[] = [];
opcoes = owlFooterOpcoes;
thisYear = new Date().getFullYear();
disciplinas: Registo[] = [];

  constructor(private contactoService: ContactoService,
              private comService: ComunicacaoService,
              private clienteService: ClienteService,
              private appService: AplicacaoService,
              private registoService: RegistoService,
              private store: DataStoreService) { }

  ngOnInit(): void {
    this.getRedesSociais();
    this.getAfiliados(1, 1);
    this.getLinksUteis(this.idGrupo);
    this.getDisciplinas(this.idGrupoDisciplina, this.codIdioma);
    this.getContactos();
  }


  getContactos(): void {
    this.subscricoes.push(
      this.contactoService.getLocais().subscribe({
        next: (res: any) => {
          if (res.objecto.length > 0){
            const locais = res.objecto;
            this.sede = locais.find(x => x.tipoLocal.toLowerCase() === 'sede' || x.tipoLocal.toLowerCase() === 'escritório');
            if (this.sede) {
              this.contactos = this.sede?.contactos;
              for (const item of this.contactos) {
                if (item.tipoContacto.toLowerCase() === 'e-mail') {this.listaContactos?.emails.push(item); }
                if (item.tipoContacto.toLowerCase() === 'telemóvel') {this.listaContactos?.telemoveis.push(item); }
                if (item.tipoContacto.toLowerCase() === 'telefone') {this.listaContactos?.telefones.push(item); }
                if (item.tipoContacto.toLowerCase() === 'fax') {this.listaContactos?.fax.push(item); }
              }
            }
          }
        },
        error: (error: any) => {
          console.log('Erro ao buscar contactos: ', error);
        }
      })
    );
  }


  getRedesSociais(): void {
    this.subscricoes.push(
      this.comService.getRedesStore().subscribe((store: any) => {
        if (store) {
          this.redes = store;
        } else {
          this.subscricoes.push(
            this.comService.getRedesSociais().subscribe((res: any) => {
              this.redes = res.objecto;
              this.comService.setRedesStore(res.objecto);
            })
          );
        }
      })
    );
  }

  getAfiliados(idEntidade: number, codFiltro: number): any {
    this.subscricoes.push(
      this.clienteService.getAfiliados().subscribe(store => {
        if (store) {
          this.clubes = store;
        } else {
          this.subscricoes.push(
            this.clienteService.getClientes(idEntidade, codFiltro).subscribe({
              next: (res: any) => {
                this.clubes = res.objecto;
                this.clienteService.setAfiliados(res.objecto);
              },
              error: (erro: any) => {
              }
            })
          );
        }
      })
    );
  }

  getLinksUteis(idGrupo: number): void {
    this.subscricoes.push(
      this.registoService.getRegistosImgs(idGrupo).subscribe((res: any) => {
        this.linksUteis = res.objecto;
      })
    );
  }

  getDisciplinas(idGrupoDisciplina: number, codIdioma: string): void {
    this.subscricoes.push(
      this.registoService.getRegistosIcones(idGrupoDisciplina).subscribe((res: any) => {
        this.disciplinas = res.objecto;
      })
    );
  }

  ngOnDestroy(): void { if (this.subscricoes.length > 0){  this.subscricoes.forEach(sub => {   sub.unsubscribe(); }); } }

}
