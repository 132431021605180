import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
name: 'spaceToUnderscore',
pure: true
})
export class SpaceToUnderscorePipe implements PipeTransform {
    rgx = new RegExp(' ', 'gi');
    public transform(valor: any): any {
        if (valor && typeof(valor) === 'string') {
           return valor.replace(this.rgx, '-').replace('/', '').toLowerCase();
        }
    }
}
