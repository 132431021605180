import { BrowserModule } from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import {SharedModule} from './shared/modulos/shared.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localePt from '@angular/common/locales/pt';
import {registerLocaleData, ViewportScroller} from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import {Router, Scroll} from "@angular/router";
import {filter} from "rxjs/operators";

registerLocaleData(localePt);
@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    HttpClientModule,
    NgxPaginationModule
  ],
  providers: [
    {provide: LOCALE_ID, useValue: 'pt'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(rota: Router, viewportScroller: ViewportScroller) {
    rota.events.pipe(
      filter((e): e is Scroll => e instanceof Scroll)
    ).subscribe(e => {
      if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition([0, 0]);
      } else if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
          viewportScroller.setOffset([0, 80]);
        }, 100);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
}
