import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Banner} from "../../../entidades/slider";

@Component({
  selector: 'app-pagina-intro',
  template: `
    <div class="bg-azul-200 py-2"></div>
    <section class="py-5" style=" background:linear-gradient(to left, rgba(0,0,0,.1), rgba(0,0,0,.1)), url('{{banner?.imagem}}')">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <h1 class="text-primary text-uppercase">{{banner?.textos[0]?.titulo}}</h1>
          </div>
        </div>
      </div>
    </section>`,
  styleUrls: ['./pagina-intro.component.scss']
})
export class PaginaIntroComponent implements OnChanges {
@Input() titulo;
@Input() banner: Banner;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.titulo && changes.titulo.currentValue) {
     this.mudarTitulo(this.titulo);
    }
  }

   mudarTitulo(titulo): void {
    this.titulo = titulo;
  }
}
