import { Pipe, PipeTransform } from '@angular/core';
import {Registo} from '../entidades/registo';

@Pipe({
  name: 'takeActiveItem'
})
export class TakeActiveItemPipe implements PipeTransform {
constructor() {
}
 public transform(registos: Registo[], limite?: number): any {
  let registosActivos: Registo[] = [];
  if(registos && registos.length > 0) {
      registosActivos = registos.filter((x) => x.destaque === true && x.activo === true);
    }
  if (limite && limite > 0){
    return registosActivos.slice(0, limite);
     } else {
       return registosActivos;
     }
  }

}
