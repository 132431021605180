// @ts-ignore

import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'ordernarData'})
export class OrdenarDataPipe implements PipeTransform {
  constructor() {
  }

  public transform(items: any, ...args): any {
    let itemsOrdenados: any[] = [];
    if (items && items.length > 0) {

      itemsOrdenados = items.sort((a, b) => {
        const dtA = new Date(a.data).getTime();
        const dtB = new Date(b.data).getTime();
        return dtB > dtA ? 1 : -1;
      });
    }
    return itemsOrdenados;
  }


}

