import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tipoFicheiro'
})
export class TipoFicheiroPipe implements PipeTransform {
constructor() {
}
 public transform(valor: any): any {
     let arrayStr = '';
     let extensaoFicheiro = '';
     if (valor) {
       // tipoFicheiro = primeiraParte.split('/')[1];
        arrayStr = valor.split('.');
        extensaoFicheiro = arrayStr[1];
        console.log('Split Length: ', extensaoFicheiro.length);
        switch (extensaoFicheiro) {
            case 'pdf': return  'assets/images/icons/file_pdf_red.svg';
            case 'msword': return  'assets/images/file-types/005-documents.svg';
            case 'vnd.openxmlformats-officedocument.wordprocessingml.document': return  'assets/images/icons/word-icon.svg';
            // Ficheiro do Excel
            case 'vnd.openxmlformats-officedocument.spreadsheetml.sheet': return  'assets/images/icons/xcel-icon.svg';
            // Ficheiro do PowerPoint
            case 'vnd.openxmlformats-officedocument.presentationml.presentation': return  'assets/images/icons/powerpoint-icon.svg';
            case 'jpeg': return  'assets/images/icons/ficheiros/_icon_red_1.svg';
            case 'jpg': return 'assets/images/icons/ficheiros/_icon_red_1.svg';
            case 'png': return 'assets/images/icons/ficheiros/_icon_red_2.svg';
            case 'svg': return 'assets/images/icons/ficheiros/_icon_red_1.svg';
            case 'webp': return 'assets/images/icons/ficheiros/_icon_red_1.svg';
          default: return 'assets/images/icons/file_pdf_red.svg';
        }
    }

  }

}
