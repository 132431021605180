import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {Banner} from '../../modelos/banner/banner';
import {catchError} from 'rxjs/operators';
import {Municipio} from '../../modelos/municipio/municipio';
import {Provincia} from '../../modelos/provincia/provincia';

@Injectable({
  providedIn: 'root'
})
export class AplicacaoService {
 baseUrlCMS: string;
 urlAPI: string;
  constructor(private http: HttpClient) {
    this.baseUrlCMS = environment.api_fan_cms;
  }

  getBanners(idMenu: string, idbanner?: number): Observable<Banner[]> {
    return this.http
      .get(this.baseUrlCMS + 'BannersAPI/GetBanners?idIdioma=1&idMenu=' + idMenu.trim() + '&idbanner=' + idbanner)
      .pipe(catchError(null));
  }

  getBannersAPI(idMenu: string, idbanner?: number): Observable<Banner[]> {
    return this.http
      .get(this.baseUrlCMS + 'BannersAPI/GetBanners?idIdioma=1&idMenu=' + idMenu.trim() + '&idbanner=' + idbanner)
      .pipe(catchError(null));
  }

  getBanner(idMenu: string, idbanner?: number, id?: number): Observable<Banner> {
    return this.http
      .get(this.baseUrlCMS + 'BannersAPI/GetBanners?idIdioma=1&idMenu=' + idMenu.trim() + '&idbanner=' + idbanner + '&id=' + id)
      .pipe(catchError(null));
  }

  getProvincias(): Observable<Provincia[]> {
    return this.http
      .get(this.baseUrlCMS + 'LocalizacoesAPI/provincias?id=1').pipe(catchError(null));
  }

  getMunicipios(provinciaId?: number): Observable<Municipio[]> {
    return this.http
      .get(this.baseUrlCMS + 'LocalizacoesAPI/municipios?id=' + provinciaId).pipe(catchError(null));;
  }

  getLinksUteis(idGrupo: number, codIdioma: string, registoActivo: boolean): any {
    return this.http.get<any>(this.baseUrlCMS + 'registos/Lista/' + idGrupo + '/' + codIdioma + '/' + registoActivo);
  }
}
