import {Component, OnDestroy, OnInit} from '@angular/core';
import {Cliente} from '../../modelos/cliente/cliente';
import {Subscription} from 'rxjs';
import {owlFooterOpcoes} from '../../config/app.config';
import {ClienteService} from '../../servicos/cliente.service';

@Component({
  selector: 'app-partial-parceiros',
 template: `
    <section class="bg-white">
      <div class="pt-5 pb-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <ul class="nav categoria-lista ">
                <li class="nav-item pl-0">
                  <h2 class="nav-link text-primary fw-bold ps-0 text-uppercase" >
                   Parceiros
                  </h2>
                </li>
                <li class="nav-item">
                  <span class="nav-link"  routerLink="/parceiros" >Todos</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="pb-5">
      <div class="container py-4">
        <owl-carousel-o [options]="opcoes">
          <ng-container *ngFor="let parceiro of parceiros; let i = index">
            <ng-template carouselSlide>
              <div class=" cliente-logo-wrapper">
                <div class="p-1 logo-img">
                  <img *ngIf="parceiro.logo" [src]="parceiro.logo" [alt]="parceiro.nome">
                  <span *ngIf="!parceiro.logo" [innerText]="parceiro.nome"></span>
                </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
        <div class="row">
          <div class="col-md-2 mb-3 col-sm-3 col-6" *ngFor="let parceiro of parceiros">
            <div class="h-100 overflow-hidden">
              <div class="p-2 d-flex justify-content-center">
                <img [src]="parceiro.logo" class="w-auto"  style="height: 100px" alt="{{parceiro.nome}}">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./partial-parceiros.component.scss']
})
export class PartialParceirosComponent implements OnInit, OnDestroy {
  parceiros: Cliente[] = [];
  subParceiros: Subscription;
  opcoes = owlFooterOpcoes;
  constructor(private clienteService: ClienteService) { }

  ngOnInit(): void { }
  ngOnDestroy(): void {
    if (this.subParceiros){
      this.subParceiros.unsubscribe();
    }
  }

}
