import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from "../../../environments/environment";

@Injectable({providedIn: 'root'})
export class SuporteService {
  api?: string;
  constructor(private http: HttpClient) {
    this.api = environment.cms_url;
  }

  getTodasFAQs(): any{
    return this.http.get(this.api + 'faqs');
  }

  getFaqsPorIdioma(codIdioma?: string): any{
    return this.http.get(this.api + 'faqs/listagem/' + codIdioma);
  }

  getFaqsPorArea(idArea: number, codIdioma?: string): any{
    return this.http.get(this.api + 'faqs/' + idArea + '/' + codIdioma);
  }

  getFAQ(idFaq: number, codIdioma?: string): any {
      return this.http.get(this.api + 'faqs/texto/' + idFaq + '/' + codIdioma);
  }

  getAreaFAQs(codIdioma?: string): any {
    return this.http.get(this.api + 'areasFaqs/areas/' + codIdioma);
  }

  getConfiguracoes(): any {
    return this.http.get(this.api + 'gerais/configuracoes');
  }

}
