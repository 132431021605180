import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  template: `
  <div class="py-5 flex justify-content-center text-center">
    <div class="lds-ripple"><div></div><div></div></div> <div class="py-3"><p>A carregar, por favor aguarde ....</p></div>
  </div>,
 `,
  styles: [
    `
      .lds-ripple {
        display: inline-block;
        position: relative;
        width: 100px;
        height: 100px;
      }
      .lds-ripple div {
        position: absolute;
        border: 4px solid #092f49;
        opacity: 1;
        border-radius: 50%;
        animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
      }
      .lds-ripple div:nth-child(2) {
        animation-delay: -0.5s;
      }
      @keyframes lds-ripple {
        0% {
          top: 36px;
          left: 36px;
          width: 0;
          height: 0;
          opacity: 1;
        }
        100% {
          top: 0px;
          left: 0px;
          width: 72px;
          height: 72px;
          opacity: 0;
        }
      }
    `
  ]
})
export class LoaderComponent  {
  constructor() { }
}
