import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {owlFooterOpcoes} from '../../../config/app.config';
import {ClienteService} from '../../../servicos/cliente.service';
import {ParceirosStoreService} from '../../../services/data-store/parceiros-store.service';
import {Cliente} from "../../../entidades/cliente";
import {OwlOptions} from "ngx-owl-carousel-o";


@Component({
  selector: 'app-slider-parceiros',
  template: `
    <section class="bg-white">
      <div class="pt-5 pb-3">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <ul class="nav categoria-lista justify-content-between">
                <li class="nav-item pl-0">
                  <h2 class="nav-link text-primary fw-bold ps-0  text-uppercase" >
                  <span *ngIf="tituloSeccao"> {{tituloSeccao}}</span>
                  <span *ngIf="!tituloSeccao"> Parceiros</span>
                  </h2>
                </li>
                <li class="nav-item">
                  <span class="nav-link hvr-push-direita fw-bold text-uppercase btn bg-cinza-200 rounded-0"  routerLink="/parceiros" >
                    <span>Ver todos</span>
                      <span class="ps-3 icon-btn"><i class="bi-arrow-right"></i></span>
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="pb-5">
      <div class="container py-4">
        <div class="pb-5">
          <owl-carousel-o [options]="opcoesSlider">
            <ng-container *ngFor="let parceiro of parceiros | clienteDestaque: true; let i = index">
              <ng-template carouselSlide>
                <div class="parceiros-logo-wrapper">
                  <div class="p-1">
                    <div *ngIf="parceiro?.fotografia" class="p-1 d-flex justify-content-center " style="height: 100px">
                      <img *ngIf="parceiro?.fotografia" [src]="parceiro?.fotografia" loading="lazy" [alt]="parceiro?.nome">
                    </div>
                    <div class="text-center">
                      <div *ngIf="!parceiro?.fotografia" class="p-3 bg-cinza-100" style="border-radius: 50px">
                        <h5 class="small mb-0 text-primary ">{{parceiro?.nome}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
        <owl-carousel-o [options]="opcoes">
          <ng-container *ngFor="let parceiro of parceiros | clienteDestaque: false; let i = index">
            <ng-template carouselSlide>
              <div class="parceiros-logo-wrapper">
               <div class="p-1">
                 <div *ngIf="parceiro?.fotografia" class="p-1 d-flex justify-content-center " style="height: 75px">
                   <img *ngIf="parceiro?.fotografia" [src]="parceiro?.fotografia" loading="lazy" [alt]="parceiro?.nome">
                 </div>
                 <div class="text-center">
                   <div *ngIf="!parceiro?.fotografia" class="p-3 bg-cinza-100" style="border-radius: 50px">
                     <h5 class="small mb-0 text-primary ">{{parceiro?.nome}}</h5>
                   </div>
                 </div>
               </div>
              </div>
            </ng-template>
          </ng-container>
        </owl-carousel-o>
      </div>
    </section>
  `,
  styleUrls: ['./slider-parceiros.component.scss']
})
export class SliderParceirosComponent implements OnInit, OnDestroy {
  opcoesSlider: OwlOptions = {
    rewind: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 2000,
    autoplay: true,
    margin: 15,
    nav: true,
    navText: ['', ''],
    responsive: {
      0: {
        items: 3
      },
      400: {
        items: 4
      },
      740: {
        items: 4
      },
      940: {
        items: 4
      }
    },
    // nav: true
  };
  parceiros: Cliente[] = [];
  @Input() tituloSeccao;
  opcoes = owlFooterOpcoes;
  private subscricoes: Subscription[] = [];
  constructor(private clienteService: ClienteService, private store: ParceirosStoreService) { }

  ngOnInit(): void { this.getParceiros(2, 1); }

  ngOnDestroy(): void {
    if (this.subscricoes.length > 0){
      this.subscricoes.forEach(sb => {
        sb.unsubscribe();
      });
    }
  }

  getParceiros(idEntidade: number, codFiltro: number): any {
    this.subscricoes.push(
      this.clienteService.getClientes(idEntidade, codFiltro).subscribe({
        next: (res: any) => {
          this.parceiros = res.objecto;
          this.clienteService.setParceiros(res.objecto);
        },
        error: (erro: any) => {
          console.log('Erro ao buscar clientes');
        }
      })
    );
    this.subscricoes.push(
      this.clienteService.getParceiros().subscribe(store => {
        if (store) {
       //   this.parceiros = store;
        } else {

        }
      })
    );
  }

}
