import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Form, FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ContactoService} from "../../services/contacto/contacto.service";
import {ComunicacaoService} from "../../core/servicos/comunicacao-service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-form-inscricao',
  templateUrl: './form-inscricao.component.html',
  styleUrls: ['./form-inscricao.component.scss']
})
export class FormInscricaoComponent implements OnInit {
  formInscricao: FormGroup;
  btnTexto = 'Inscrever-se';
  estaProcessar: boolean;
  spinner: boolean;
  codIdioma = environment.codIdioma;
  candidaturas = [{id: 2, nome: 'Filiado'}, {id: 1, nome: 'Associado'}, {id: 3, nome: 'Parceiro'} ];
  @Output() fechar = new EventEmitter();

  constructor(private fb: FormBuilder, private comService: ComunicacaoService) {
    this.formInscricao = this.inicForm();
  }
  ngOnInit(): void {}

  inicForm(): FormGroup {
  return this.fb.group({
      idRegisto: [0],
      idFormulario: [7],
      codIdioma: [this.codIdioma],
      nome: [''],
      email: [''],
      telefone: [''],
      assunto: [''],
      mensagem: [''],
      tipoCliente: [''],
      idTipoCliente: [''],
    });

  }

  fazerInscricao(): any{
    const camposAValidar = ['nome', 'email', 'tipoCliente'];
    for (const campo of camposAValidar) {
      if (!this.formInscricao.get(campo).value) {
        this.validarCampos(campo, this.formInscricao);
      }
      if (campo === 'email' && !this.formInscricao.get('email').value) {
        this.formInscricao.get('email').setValidators([Validators.required, Validators.email,
          Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]);
        this.formInscricao.get('email').updateValueAndValidity({onlySelf: true});
        this.formInscricao.get('email').markAllAsTouched();
      }
    }

    if (this.formInscricao.invalid){return; }

    this.estaProcessar = true;
    this.formInscricao.get('assunto').setValue('Inscrição para ' + this.formInscricao.get('tipoCliente').value);
    let modelo = this.formInscricao.value;
   // modelo.mensagem = '<p><strong>Nome: </strong>' +  this.formInscricao.value.nome + '</p>' +  '<p><strong>Email: </strong> ' + this.formInscricao.value.email + '</p>' + '<p><strong>Telefone: </strong> ' +  this.formInscricao.value.telefone + '</p>' + '<p>' +  this.formInscricao.value.mensagem + '</p>';
    modelo.mensagem = '<p>' +  this.formInscricao.value.mensagem + '</p>';
    this.comService.gravarMsgContacto(modelo).subscribe(res => {
      if (res.statusCode === 201){
        const msg = 'A sua mensagem foi enviada com sucesso!';
        this.notificarCliente(msg, 3000);
        this.resetState(msg, true);
      }

      this.estaProcessar = false;
    }, (error) => {
      this.notificarCliente('Falha ao enviar a mensagem, por favor tente mais tarde!', 3000);
    });
  }

  limparCampos(formulario: FormGroup): void {
    formulario.clearValidators();
    formulario.clearAsyncValidators();
    formulario.markAsUntouched({onlySelf: true});
    formulario.updateValueAndValidity({onlySelf: true});
    formulario.get('nome').reset();
    formulario.get('email').reset();
    formulario.get('telefone').reset();
    formulario.get('assunto').reset();
    formulario.get('mensagem').reset();
  }
  validarCampos(control, formulario: FormGroup): boolean {
    if (control !== 'email') {
      formulario.get(control).setValidators([Validators.required]);
      formulario.get(control).updateValueAndValidity();
      formulario.get(control).markAsTouched({onlySelf: true});
    }
    return;
  }

  fecharModal(): void {
    this.fechar.emit(false);
  }

  notificarCliente(msg: string, duracao?: number): any {
    this.btnTexto = msg;
    setTimeout(() => {
      this.btnTexto = 'Inscrever-se';
      this.spinner = false;
    }, duracao);
  }

  resetState(msg: any, sucesso: boolean): void {
    // this.statusMessage = msg;
    // this.loading = false;
    this.estaProcessar = false;
    setTimeout(() => {
    //  this.statusMessage = null;
      if (sucesso) {this.limparCampos(this.formInscricao); }
    }, 3000);
  }

}
