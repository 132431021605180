import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {Registo} from '../entidades/registo';
import {environment} from "../../../environments/environment";


@Injectable({
  providedIn: 'root'
})
export class RegistoService{
  api?: string;
  codIdioma = environment.codIdioma;
  noticias$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    this.api = environment.api_fan_cms;
  }

  getRegisto(idRegisto?: number): any {
    return this.http.get(this.api + 'registos/registo/' + idRegisto + '/' + this.codIdioma + '/true');
  }

  getTodosRegistos(): any {
    return this.http.get(this.api + 'registos/Listagem/' + this.codIdioma + '/true' );
  }

  getRegistosPorGrupo(idGrupo: number, estadoRegisto: boolean): any {
    return this.http.get(this.api + 'registos/' + idGrupo + '/' + this.codIdioma + '/' + estadoRegisto);
  }

  getRegistosIcones(idGrupo: number): any {
    return this.http.get(this.api + 'registos/ListarIcone/' + idGrupo + '/' + this.codIdioma);
  }
  getRegIconesConteudos(idGrupo: number): any {
    return this.http.get(this.api + 'registos/ListarIconeConteudo/' + idGrupo + '/' + this.codIdioma);
  }

  getListaRegistos(idGrupo: number, incluiCamposValor: boolean): any {
    return this.http.get(this.api + 'registos/Lista/' + idGrupo + '/' + this.codIdioma + '/' + incluiCamposValor);
  }

  getRegistosImgs(idGrupo: number): any {
    return this.http.get(this.api + 'registos/ListarImagem/' + idGrupo + '/' + this.codIdioma);
  }

  getTiposGrupo(idGrupo: number): any {
    return this.http.get(this.api + 'gruposTipos/' + idGrupo + '/' + this.codIdioma);
  }

  downloadAnexo(idArquivo: number): any {
    return this.http.get(this.api + 'arquivos/' + idArquivo);
  }

  setNoticiasStore(noticias: Registo[]): any {
    this.noticias$.next(noticias);
  }

  getNoticiasStore(): any {
    return this.noticias$.asObservable();
  }
}
