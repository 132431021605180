<section class="bg-cinza-100 py-5">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-primary col-md-6">Quer aprender sem sair de casa? Baixe os
          aplicaticativos da FINA e Swim Mobile</h2>
      </div>
    </div>
  </div>
</section>
