import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {Contacto} from '../../modelos/contacto/contacto';
import {catchError} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {ListaContacto} from '../../modelos/contacto/lista-contacto';
import {RedeSocial} from '../../core/modelos/rede-social';

@Injectable({
  providedIn: 'root'
})
export class ContactoService {
  baseUrlCMS: string;
  api: string;
  redesStore = new BehaviorSubject<RedeSocial[]>(null);
  contactosStore$ = new BehaviorSubject<ListaContacto>(null);
  sedeStore$ = new BehaviorSubject<any>(null);

  constructor(private http: HttpClient) {
    this.baseUrlCMS = environment.api_cms;
    this.api = environment.api_fan_cms;
  }

  getRedesSociais(): any{
    return  this.http.get(this.api + 'redesSocias');
  }
  setRedesStore(redesSociais: RedeSocial[]): void {
    this.redesStore.next(redesSociais);
  }

  setContactosStore(contactos: ListaContacto): void {
    this.contactosStore$.next(contactos);
  }

  setSedeStore(sede: any): void {
    this.sedeStore$.next(sede);
  }

  sedeStore(): any {
    return this.sedeStore$.asObservable();
  }

  contactosStore(): any {
    return this.contactosStore$.asObservable();
  }

  getRedesStore(): any {
    return this.redesStore.asObservable();
  }

  getLocal(idLocal: number, codIdioma: string): any {
    return this.http.get(this.api + 'locais/local/' + idLocal + '/' + codIdioma);
  }
  getLocais(): any {
    return this.http.get(this.api + 'locais');
  }
  subscreverNewsletter(modelo: any): any{
    return this.http.post(this.api + 'newsletters', modelo);
  }

  gravarMsgContacto(modelo: any): any {
    return this.http.post(this.api + 'formulariosRegistos', modelo);
  }

}  // Fim do Service //
