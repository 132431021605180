import {Component, HostListener, Input, OnInit} from '@angular/core';
import {AppConfiguracao} from "../../../core/entidades/app-configuracao";

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
 @Input() appConfiguracao!: AppConfiguracao;
navMobileAberta: boolean = true;
  constructor() { }

  ngOnInit(): void {
  }
  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e): void {
    const navbar = document.getElementById('sec-navbar-header');
    if (window.pageYOffset > 0) {
      navbar.classList.add('navbar-header-fixed');
    } else {
      navbar.classList.remove('navbar-header-fixed');
    }
  }

  abrirNavMobile(): void {
   this.navMobileAberta = true;
  }

  fecharNavMobile(): void {
    const mobileNav = document.getElementById('navbarSupportedContent');
    if(mobileNav){
      if(mobileNav.classList.contains('show')){
        mobileNav.classList.remove('show');
      }
    }
  }
}
