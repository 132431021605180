import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from '@angular/router';
import {RodapeComponent} from '../componentes/rodape/rodape.component';
import {NavbarComponent} from '../componentes/navbar/navbar.component';
import {CarouselModule} from "ngx-owl-carousel-o";
import {PartialModule} from "../common/partials/partial.module";

const componentes = [
  NavbarComponent,
  RodapeComponent
];

@NgModule({
  declarations: componentes,
  exports: [
    NavbarComponent,
    RodapeComponent
  ],
    imports: [
        CommonModule,
        RouterModule,
        CarouselModule,
        PartialModule
    ]
})
export class SharedModule { }
