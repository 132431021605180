<section class="d-block navbar-wrapper shadow" id="sec-navbar-header">
<div class="container-fluid">
  <nav class="navbar navbar-expand-xl py-0 my-0  ps-0">
    <div class="container-fluid  px-0" >
      <a class="navbar-brand bg-white px-5 border-0 skew-30d-right" routerLink="/home" >
         <span class="px-md-5">
            <img src="assets/images/logo/fan_logo_antigo.jpg" class="skew-30d-left" style="width: 65px; " alt="FAN">
         </span>
      </a>
      <button class="navbar-toggler" (click)="abrirNavMobile()" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="text-white">
         MENU <i class="bi-list"></i>
        </span>
      </button>
      <div class="collapse container navbar-collapse bg-azul-marinho" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-lg-0 w-100 nav-fill">
          <li class="nav-item dropdown" id="fan-dropdown" routerLinkActive="menu-activo">
            <a  class="nav-link dropdown-toggle hvr-push-direita" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              FAN
            </a>
            <ul class="dropdown-menu rounded-0 bg-azul-marinho" aria-labelledby="navbarDropdown">
              <li><a (click)="fecharNavMobile()" class="dropdown-item " [routerLink]="['/fan/quem-somos']">
                <span><i class="bi-caret-right-fill"></i></span> Quem somos</a></li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <a (click)="fecharNavMobile()" class="dropdown-item" [routerLink]="['/fan/estrutura']">
                   <span><i class="bi-caret-right-fill"></i></span> Estrutura orgânica
                </a>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <a (click)="fecharNavMobile()" class="dropdown-item" [routerLink]="['/fan/estrutura']" fragment="sec-regulamentos">
                  <span><i class="bi-caret-right-fill"></i></span> Regulamentos
                </a>
              </li>
              <li><hr class="dropdown-divider"></li>
              <li>
                <a (click)="fecharNavMobile()" class="dropdown-item" [routerLink]="['/fan/estrutura']" fragment="sec-estatutos">
                  <span><i class="bi-caret-right-fill"></i></span> Estatutos
                </a>
              </li>
            </ul>
          </li>

          <li class="nav-item" routerLinkActive="menu-activo">
            <a (click)="fecharNavMobile()" class="nav-link hvr-push-direita" routerLink="/disciplinas" >Disciplinas</a>
          </li>

          <li class="nav-item dropdown" id="news-dropdown" routerLinkActive="menu-activo">
            <a  class="nav-link dropdown-toggle hvr-push-direita" href="#" id="newsDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Informa&ccedil;&atilde;o
            </a>
            <ul class="dropdown-menu rounded-0 bg-azul-marinho" aria-labelledby="newsDropdown">
              <li>
                <a (click)="fecharNavMobile()" class="dropdown-item pe-4" [routerLink]="['/informacao/1/publica']">
                  <span><i class="bi-caret-right-fill"></i></span> Informa&ccedil;&atilde;o pública
                </a>
              </li>
              <li>
                <a (click)="fecharNavMobile()" class="dropdown-item pe-4" [routerLink]="['/clubes']">
                <span><i class="bi-caret-right-fill"></i></span> Filiados </a>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown" id="evento-dropdown" *ngIf="false">
            <a  class="nav-link  hvr-push-direita" href="#" id="eventoDropdown" routerLinkActive="menu-activo" role="button" data-bs-toggle="dropdown" aria-expanded="false">
              Eventos
            </a>
            <ul class="dropdown-menu rounded-0 bg-azul-marinho" aria-labelledby="eventoDropdown">
              <li (click)="fecharNavMobile()"><a  class="dropdown-item" [routerLink]="['/eventos/estado/5']">
                <span><i class="bi-caret-right-fill"></i></span> Eventos agendados</a></li>
              <li><hr class="dropdown-divider"></li>
              <li (click)="fecharNavMobile()">
                <a   class="dropdown-item" [routerLink]="['/eventos/estado/6']">
                  <span><i class="bi-caret-right-fill"></i></span> Eventos realizados
                </a>
              </li>
              <li (click)="fecharNavMobile()">
                <a  class="dropdown-item" [routerLink]="['/eventos/categoria/0/todos']">
                  <span><i class="bi-caret-right-fill"></i></span>Todos eventos
                </a>
              </li>
            </ul>
          </li>
          <li (click)="fecharNavMobile()" class="nav-item" routerLinkActive="menu-activo">
            <a  class="nav-link hvr-push-direita" [routerLink]="['/eventos']">Eventos</a>
          </li>

          <li (click)="fecharNavMobile()" class="nav-item" routerLinkActive="menu-activo">
            <a  class="nav-link hvr-push-direita" routerLink="/galeria">Galeria</a>
          </li>
          <li class="nav-item" routerLinkActive="menu-activo">
            <a (click)="fecharNavMobile()" class="nav-link hvr-push-direita" routerLink="/contactos">Contactos</a>
          </li>
          <li class="nav-item" *ngIf="false">
            <a class="nav-link">
              <span><i class="bi-search"></i></span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</div>
</section>

