import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Seccao} from "../../../core/entidades/seccao";

@Component({
  selector: 'app-sec-add',
  template: `
    <section class="py-5 " *ngIf="false">
      <div class="container">
        <div class="row px-2">
        <div class="col-12 bg-cinza-100 py-5" style="background: linear-gradient(to right, rgba(20,50,50,.5), rgba(5,5,6,.6)), url(''); background-position: center">
          <div class="row px-3">
            <div class="col-xxl-9 col-lg-10 col-md-9 col-sm-12 mx-auto text-center">
              <h3 class=" ff-serrat py-3 text-white " ><span class="fw-bold">Estamos a viver um novo capítulo na história <br>da natação em Angola.</span> <br>Você pode fazer parte.  Inscreva-se!</h3>
            </div>
            <div class="col-md-12 overflow-hidden">
              <div class="w-100  d-sm-flex justify-content-sm-center align-items-sm-center buttons-wrapper text-center">
                <button (click)="abrirModal()" class="btn-cta fw-light text-center shadow mx-3 mb-3 btn bg-azul-marinho p-3 rounded-0 fs-5 px-lg-5  hover-scale text-white">Torne-se um parceiro da FAN</button>
                <button (click)="abrirModal()" class="btn-cta fw-light text-center btn bg-azul-marinho mb-3 p-3 mx-3 rounded-0 fs-5 px-lg-5  hover-scale text-white">Torne-se um Filiado/Associado</button>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>

    </section>

    <section class="py-5" *ngIf="false">
      <div class="container">
        <div class="row ">
          <div class="col-md-12 " (click)="abrirModal()">
            <div class="py-4">
              <img loading="lazy" src="assets/images/add.jpg" class="w-100" alt="Add Banner">
            </div>
          </div>
        </div>
      </div>
    </section>
  `,
  styleUrls: ['./sec-add.component.scss']
})
export class SecAddComponent implements OnInit {
   mostrarModal: boolean;
  @Input() titulo = '';
  @Input() seccaoInscricao: Seccao;
  @Input() seccaoPublicidade: Seccao;

   @Output() mostrar = new EventEmitter();

   constructor() { }

  ngOnInit(): void {
  }
  abrirModal(): void {
     this.mostrar.emit(true);
  }

}


@Component({
  selector: 'app-formulario-inscricao',
  template: `
  `,
  styleUrls: ['./sec-add.component.scss']
}) export class FormularioInscricaoComponent implements OnInit{

  formInscricao: FormGroup;
  mostrarModal: boolean;
  candidaturas = [ 'Filiado', 'Candidato' ];
  @Output() fechar = new EventEmitter();

  constructor(private fb: FormBuilder) {}
  ngOnInit(): void {
    this.formInscricao = this.fb.group({
      nome: [],
      email: [],
      telefone: [],
      modalidades: []
    });
  }


  fecharModal(): void {
    this.fechar.emit(false);
  }
}
