import {OwlOptions} from 'ngx-owl-carousel-o';

export const owlHeroConfig: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  autoplay: true,
  margin: 0,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 1
    },
    940: {
      items: 1
    },
    1400: {
      items: 1
    }
  },
  nav: true
};


export const owlCarouselConfig: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  autoplay: true,
  margin: 0,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 2
    },
    940: {
      items: 2
    }
  },
  nav: true
};


export const owlParceirosConfig: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 700,
  autoplay: true,
  margin: 0,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 2
    },
    940: {
      items: 2
    }
  },
  nav: true
};

export const owlSlideConfig: OwlOptions = {
  loop: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  dots: false,
  navSpeed: 2000,
  autoplay: true,
  nav: true,
  margin: 10,
  navText: ['', ''],
  responsive: {
    0: {
      items: 1
    },
    400: {
      items: 1
    },
    740: {
      items: 2
    },
    940: {
      items: 3
    }
  },

};

export const owlFooterOpcoes: OwlOptions = {
  loop: false,
  rewind: true,
  mouseDrag: true,
  touchDrag: true,
  pullDrag: true,
  nav: true,
  dots: false,
  navSpeed: 2000,
  autoplay: true,
  margin: 15,
  slideBy: 2,
  navText: ['',''],
  responsive: {
    0: {
      items: 3
    },
    400: {
      items: 4
    },
    740: {
      items: 4
    },
    940: {
      items: 6
    }
  },
 // nav: true
};
