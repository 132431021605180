import {Contacto} from './contacto';

export class ListaContacto {
  constructor(
    public emails?: Contacto[],
    public telemoveis?: Contacto[],
    public telefones?: Contacto[],
    public fax?: Contacto[],
  ) {}
}
