import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Contacto} from '../../modelos/contacto/contacto';
import {RedesSociais} from '../../modelos/redes-sociais/redes-sociais';
import {Cliente} from "../../modelos/cliente/cliente";

@Injectable({
  providedIn: 'root'
})
export class DataStoreService {
contactos = new BehaviorSubject<any>(null);
redes = new BehaviorSubject<any>(null);
clubes = new BehaviorSubject<any>(null);

  constructor() { }

  setContactosData(contactos: Contacto[]): void{
    this.contactos.next(contactos);
  }
  setClubesData(clubes: Cliente[]): void{
    this.clubes.next(clubes);
  }
  getClubesData(): Observable<Cliente[]>{
    return this.clubes.asObservable();
  }

  getContactosData(): Observable<Contacto[]>{
    return this.contactos.asObservable();
  }

  setRedesData(redes: RedesSociais): void{
    this.redes.next(redes);
  }
  getRedesData(): Observable<RedesSociais>{
    return this.redes.asObservable();
  }



}
