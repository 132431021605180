import {Component, Input, Output, OnInit, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-sec-head',
  template:  `
    <div class="pt-5 pb-3">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="col-12"> <h2 class="nav-link text-primary fw-bold ps-0 text-uppercase">{{titulo}}</h2></div>
          </div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./sec-head.component.scss']
})
export class SecHeadComponent implements OnInit {
@Input() categorias: any;
@Input() inputIdCategoria;
@Input() titulo;
@Input() subTitulo;
@Input() linkTodas;
@Output() categoria: EventEmitter<any> = new EventEmitter();
idCat: number;

  constructor() { }

  ngOnInit(): void {
    if (this.inputIdCategoria){
      this.idCat = this.inputIdCategoria;
    }
  }

filtrarCategoria(categoria?: any): void{
   if (categoria.idCategoria > 0){
       this.idCat = categoria.idCategoria;
       this.categoria.emit(categoria);
     } else {
       this.idCat = null;
       this.categoria.emit('todos');
     }
}
}
