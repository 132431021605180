import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';
import { SpaceToUnderscorePipe } from './space-to-underscore.pipe';
import {TipoFicheiroPipe} from './tipo-ficheiro.pipe';
import {QuebrarTextoPipe} from './quebrar-texto.pipe';
import { TakeActiveItemPipe } from './take-active-item.pipe';
import { OrdenarPipe } from './ordenar.pipe';
import {OrdenarDataPipe} from './ordenar-data.pipe';
import {FiltrarClientesDestaquePipe} from './filtrar-clientes-destaque.pipe';
import {OrdenarEventosDataPipe} from './ordenar-eventos-data.pipe';
const filtros = [SpaceToUnderscorePipe, SafePipe, TipoFicheiroPipe, QuebrarTextoPipe, TakeActiveItemPipe, OrdenarPipe,
  OrdenarDataPipe, FiltrarClientesDestaquePipe, OrdenarEventosDataPipe];

@NgModule({
    declarations: filtros,
    imports: [],
    exports: filtros
})
export class PipeModule {}
