// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const BASE_URL = 'https://kiami-back.fanatacao.ao/';
const BASE_URL = 'https://fanatacao-back.kiami.ao/';
const URLCMSDEMO = 'https://appdemo.kiami.ao/';
const URL_API = BASE_URL + 'cms/api/v1/';
const URL_CMS = BASE_URL + 'cms/api/v1/';
const URL_EVE = BASE_URL + 'eve/api/v1/';
const URL_CLI = BASE_URL + 'apl/api/v1/';

export const environment = {
  production: false,
  api_link: URL_API,
  api_eventos: URL_EVE,
  api_clientes: URL_CLI,
  api_cms: URL_CMS,
  api_kiami: URL_API,
  api_fan_cms: URL_API,
  cms_url: URL_API,
  cms_demo: URLCMSDEMO + 'cms/api/v1/',
  codIdioma: localStorage.getItem('fan_lng') ?? 'pt',
  codigoAnalitico:  'G-8D0VEFHY4F',
  website: {
    idUtilizador: 5,
    idEstadoUtilizador: 1,
    username: 'website',
    email: 'websessao@websessao.com',
    nome: 'Utilizador Sessão Website',
    ultimoLogin: null
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// Local environment //
